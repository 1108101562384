/*@import '~lightgallery/scss/lightgallery';*/


/* You can add global styles to this file, and also import other style files */
* {
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

body {
    background: linear-gradient(to bottom right, #07325F, #000811); /* Define your gradient colors */
    min-height: 100vh; /* Ensure it covers the full viewport height */    
}

nav {
    background-color: #062b53;
}

.blurred-bg {
    background: rgba(255, 255, 255, 0.3); /* Semi-transparent background */
    backdrop-filter: blur(10px); /* Adjust blur intensity */
    -webkit-backdrop-filter: blur(10px); /* For Safari support */
    padding: 20px;
    border-radius: 10px; /* Optional: rounded corners */
  }

.card-header {
    background-color: #011a35;
}

.card-body {
    background-color: #012246;
}

.card-footer {
    background-color: #012b58;
}

.btn-secondary {
    background-color: #000811;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Colore nero con opacità al 50% */
    z-index: 1; /* Porta l’overlay sopra l’immagine */
}